import { createSlice } from "@reduxjs/toolkit";
import { IPeopleforceState } from "./types";
import { connectPeopleforce, disconnectPeopleforce } from "./actions";

const initialState: IPeopleforceState = {
  isConnectingToPeopleforce: false,
};

const peopleforceSlice = createSlice({
  name: "peopleforce",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectPeopleforce.pending, (state) => {
        state.isConnectingToPeopleforce = true;
      })
      .addCase(connectPeopleforce.fulfilled, (state) => {
        state.isConnectingToPeopleforce = false;
      })
      .addCase(connectPeopleforce.rejected, (state) => {
        state.isConnectingToPeopleforce = false;
      })
      .addCase(disconnectPeopleforce.pending, (state) => {
        state.isConnectingToPeopleforce = true;
      })
      .addCase(disconnectPeopleforce.fulfilled, (state) => {
        state.isConnectingToPeopleforce = false;
      })
      .addCase(disconnectPeopleforce.rejected, (state) => {
        state.isConnectingToPeopleforce = false;
      });
  },
});

export default peopleforceSlice.reducer; 