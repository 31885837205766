import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "services/api";
import { ACTION } from "const";
import { processApiError } from "services";
import {
  ICreateClientPayload,
  ICreateDataRecordPayload,
  IDeleteClientPayload,
  IDeleteDataRecordPayload,
  IGetAiDataSearchAnswerPayload,
  IGetAiDataSearchExtraInfoPayload,
  IGetClientPayload,
  ILinkGoogleDriveFolderPayload,
  ISyncPeopleforcePayload,
  ISyncRedtailPayload,
  ISyncSalesforcePayload,
} from "./types";

export const getClientsList = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.GET_CLIENTS_LIST,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.getClientsList();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createClient = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.CREATE_CLIENT,
  async (payload: ICreateClientPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.createClient(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getClient = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.GET_CLIENT,
  async (payload: IGetClientPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.getClient(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteClient = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.DELETE_CLIENT,
  async (payload: IDeleteClientPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.deleteClient(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const createDataRecord = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.CREATE_DATA_RECORD,
  async (payload: ICreateDataRecordPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.createDataRecord(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const linkGoogleDriveFolder = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.LINK_GOOGLE_DRIVE_FOLDER,
  async (payload: ILinkGoogleDriveFolderPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.linkGoogleDriveFolder(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getDataRecordsList = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.GET_DATA_RECORDS_LIST,
  async (clientId: string, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.getDataRecordsList(clientId);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const deleteDataRecord = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.DELETE_DATA_RECORD,
  async (payload: IDeleteDataRecordPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.deleteDataRecord(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getAiDataSearchAnswer = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.GET_AI_DATA_SEARCH_ANSWER,
  async (payload: IGetAiDataSearchAnswerPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.getAiDataSearchAnswer(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getAiDataSearchWhoIsClient = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.GET_AI_DATA_SEARCH_WHO_IS_CLIENT,
  async (payload: IGetAiDataSearchExtraInfoPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.getAiDataSearchWhoIsClient(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const getAiDataSearchDoAgenda = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.GET_AI_DATA_SEARCH_DO_AGENDA,
  async (payload: IGetAiDataSearchExtraInfoPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.getAiDataSearchDoAgenda(
        payload
      );
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const syncSalesforce = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.SYNC_SALESFORCE,
  async (payload: ISyncSalesforcePayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.syncSalesforce(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const importRedtail = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.IMPORT_REDTAIL,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.importRedtail();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const importPeopleforce = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.IMPORT_PEOPLEFORCE,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.importPeopleforce();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const syncRedtail = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.SYNC_REDTAIL,
  async (payload: ISyncRedtailPayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.syncRedtail(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);


export const syncPeopleforce = createAsyncThunk(
  ACTION.CLIENT_MANAGEMENT.SYNC_PEOPLEFORCE,
  async (payload: ISyncPeopleforcePayload, { rejectWithValue }) => {
    try {
      const response = await api.clientManagement.syncPeopleforce(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);