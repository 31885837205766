import { processApiError } from "services";
import { api } from "services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION } from "const/actions";
import { IConnectPeopleforcePayload } from "./types";

export const connectPeopleforce = createAsyncThunk(
  ACTION.PEOPLEFORCE.CONNECT,
  async (payload: IConnectPeopleforcePayload, { rejectWithValue }) => {
    try {
      const response = await api.peopleforce.connectPeopleforce(payload);
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
);

export const disconnectPeopleforce = createAsyncThunk(
  ACTION.PEOPLEFORCE.DISCONNECT,
  async (payload: void, { rejectWithValue }) => {
    try {
      const response = await api.peopleforce.disconnectPeopleforce();
      return response;
    } catch (error) {
      const msg = processApiError(error);
      return rejectWithValue(msg);
    }
  }
); 