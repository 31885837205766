import { createSlice } from "@reduxjs/toolkit";
import { IProfileState } from "./types";
import { RoleEnum } from "const";
import { getProfileDetails, updatePreferences } from "./actions";

const initialState: IProfileState = {
  loading: false,
  profileDetails: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    hashUserId: "",
    role: RoleEnum.None,
    industryType: "",
    connectedEmail: false,
    preferences: {},
  },
};

const profileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePreferences.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updatePreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.profileDetails = {
          ...state.profileDetails,
          preferences: action.payload?.preferences,
        };
      })
      .addCase(updatePreferences.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProfileDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfileDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.profileDetails = action.payload;
      })
      .addCase(getProfileDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = profileSlice.actions;
export default profileSlice.reducer;
