import { useAppDispatch, useDevFlag, useTranslation } from 'hooks';
import {
  IDynamicListItem, selectDataPoints, selectQuestions, getPostCallSummary, selectPostCallSummary, selectIsGetPostCallSummaryProcessing, selectIsGetDataPointValueProcessing,
  selectIsGetQuestionValueProcessing, selectOngoingCallBotId, getDataPointValue, getQuestionValue
} from 'store/chat-assistant';
import { useSelector } from 'react-redux';
import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { cn } from 'lib/utils';
import { v4 as uuidv4 } from 'uuid';
import { Label } from 'components/ui/label';
import { Tabs, TabsList, TabsTrigger, TabsContent, TabsTriggerFake } from 'components/ui/tabs';
import { Loading } from 'components/layout-atoms/Loading';
import { CopyValue } from 'components/common/_atoms/CopyValue';
import { MarkdownRenderer } from 'components/common/_atoms/MarkdownRenderer';
import { CrmData } from './crm/CrmData';
import { IClientDataRecord, selectActiveClient } from 'store/clientManagement';

enum BLOCK_DATA_TYPE {
  QUESTIONS,
  DATA_POINTS,
  CALL_SUMMARY,
  CALL_ACTION_POINTS,
  FOLLOW_UP_EMAIL,
}

interface IProps {
  botId: string;
  dataRecord: IClientDataRecord;
}

export const MeetingTabInfo = ({ botId, dataRecord }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isDevEnv } = useDevFlag()
  const [showCrmDataTab, setSetShowCrmDataTab] = useState(false)

  const isGetDataPointsProcessing = useSelector(selectIsGetDataPointValueProcessing);
  const dataPoints = useSelector(selectDataPoints) || [];

  const isGetQuestionsProcessing = useSelector(selectIsGetQuestionValueProcessing);
  const questions = useSelector(selectQuestions) || [];

  const isGetPostCallSummaryProcessing = useSelector(selectIsGetPostCallSummaryProcessing);
  const postCallSummary = useSelector(selectPostCallSummary);

  const ongoingCallBotId = useSelector(selectOngoingCallBotId);
  const activeClient = useSelector(selectActiveClient);

  useEffect(() => {
    dispatch(getDataPointValue({ botId, dataRecord, task_ids: [] }));
    dispatch(getQuestionValue({ botId, dataRecord, task_ids: [] }));
  }, [botId, dispatch, dataRecord])

  useEffect(() => {
    dispatch(getPostCallSummary({ botId, dataRecord }));
  }, [botId, dispatch, ongoingCallBotId, dataRecord])

  useEffect(() => {
    setSetShowCrmDataTab(!!activeClient?.redtailClientId || !!activeClient?.peopleforceCandidateId)
  }, [activeClient])

  return (
    <Tabs defaultValue="collectedInfo" className="flex flex-col max-h-full">
      <TabsList className='flex w-full'>
        <TabsTrigger className='grow' value="collectedInfo">{t('component.MeetingTabInfo.tabs.collectedInfo.tab')}</TabsTrigger>
        <TabsTrigger className='grow' value="summary">{t('component.MeetingTabInfo.tabs.summary.tab')}</TabsTrigger>
        <TabsTrigger className='grow' value="actionPoints">{t('component.MeetingTabInfo.tabs.actionPoints.tab')}</TabsTrigger>
        <TabsTrigger className='grow' value="emailFollowUp">{t('component.MeetingTabInfo.tabs.emailFollowUp.tab')}</TabsTrigger>
        {/* <TabsTriggerFake className='grow' content={t('component.MeetingTabInfo.tabs.fullSummary.tab')} tooltip={t('general.availableSoon')} />
        <TabsTriggerFake className='grow' content={t('component.MeetingTabInfo.tabs.planningData.tab')} tooltip={t('general.availableSoon')} /> */}
        {/* {isDevEnv && showCrmDataTab && ( */}
        {showCrmDataTab && (
          <TabsTrigger className='grow' value='crmData'>{t('component.MeetingTabInfo.tabs.crmData.tab')}</TabsTrigger>
        )}
      </TabsList>

      <TabsContent value="collectedInfo" className='space-y-6 overflow-auto h-full pr-1'>
        <BlockData
          label={t('component.MeetingTabInfo.tabs.summary.dataPoints')}
          labelDesc={t('component.MeetingTabInfo.tabs.summary.dataPointsDesc')}
          data={dataPoints}
          type={BLOCK_DATA_TYPE.DATA_POINTS}
          isProcessing={isGetDataPointsProcessing}
        />

        <BlockData
          label={t('component.MeetingTabInfo.tabs.summary.questions')}
          labelDesc={t('component.MeetingTabInfo.tabs.summary.questionsDesc')}
          data={questions}
          type={BLOCK_DATA_TYPE.QUESTIONS}
          isProcessing={isGetQuestionsProcessing}
        />
      </TabsContent>

      <TabsContent value="summary" className='space-y-6 overflow-auto h-full pr-1'>
        <BlockData
          label={t('component.MeetingTabInfo.tabs.summary.summary')}
          labelDesc={t('component.MeetingTabInfo.tabs.summary.summaryDesc')}
          data={[{
            value: ongoingCallBotId === botId ? t('component.MeetingTabInfo.aiDataNotAvailableExplanation') : postCallSummary?.summary || t('component.MeetingTabInfo.aiNotFinishedProcessing'),
            id: uuidv4(), text: ''
          }]}
          type={BLOCK_DATA_TYPE.CALL_SUMMARY}
          isProcessing={isGetPostCallSummaryProcessing}
          responseClassName='h-auto'
        />
      </TabsContent>

      <TabsContent value="actionPoints" className='space-y-6 overflow-auto h-full pr-1'>
        <BlockData
          label={t('component.MeetingTabInfo.tabs.summary.actionPoints')}
          labelDesc={t('component.MeetingTabInfo.tabs.summary.actionPointsDesc')}
          data={[{
            value: ongoingCallBotId === botId ? t('component.MeetingTabInfo.aiDataNotAvailableExplanation') : postCallSummary?.action_items || t('component.MeetingTabInfo.aiNotFinishedProcessing'),
            id: uuidv4(), text: ''
          }]}
          type={BLOCK_DATA_TYPE.CALL_ACTION_POINTS}
          isProcessing={isGetPostCallSummaryProcessing}
          responseClassName="h-auto"
        />
      </TabsContent>

      <TabsContent value="emailFollowUp" className='space-y-6 overflow-auto h-full pr-1'>
        <BlockData
          label={t('component.MeetingTabInfo.tabs.emailFollowUp.emailFollowUp')}
          labelDesc={t('component.MeetingTabInfo.tabs.emailFollowUp.emailFollowUpDesc')}
          data={[{
            value: ongoingCallBotId === botId ? t('component.MeetingTabInfo.aiDataNotAvailableExplanation') : postCallSummary?.follow_up_mail || t('component.MeetingTabInfo.aiNotFinishedProcessing'),
            id: uuidv4(), text: ''
          }]}
          type={BLOCK_DATA_TYPE.FOLLOW_UP_EMAIL}
          isProcessing={isGetPostCallSummaryProcessing}
          responseClassName="h-auto"
        />
      </TabsContent>

      <TabsContent value="crmData" className='space-y-6 overflow-auto h-full pr-1'>
        <CrmData dataRecord={dataRecord} />
      </TabsContent>
    </Tabs>
  );
};


interface IBlockData {
  label: string | ReactNode,
  labelDesc?: string | ReactNode,
  data: IDynamicListItem[],
  type: BLOCK_DATA_TYPE,
  isProcessing?: boolean,
  responseClassName?: string
}

const BlockData = ({ label, labelDesc, data, type, isProcessing, responseClassName }: IBlockData) => {
  const [displayData, setDisplayData] = useState<IDynamicListItem[]>([]);
  const [copyFormattedData, setCopyFormattedData] = useState<string>('');

  useEffect(() => {
    const sortedData = [...data?.filter(item => !!item.value), ...data?.filter(item => !item.value)];

    setDisplayData(sortedData);
    if ([BLOCK_DATA_TYPE.QUESTIONS, BLOCK_DATA_TYPE.DATA_POINTS].includes(type)) {
      setCopyFormattedData(sortedData?.map(item => `${item.text}\t${item.value || ''}`).join('\n'));
    } else {
      setCopyFormattedData(sortedData?.map(item => `${item.value || ''}`).join('\n').replace(/\**/g, ''));
    }
  }, [data, type])

  return (
    <div className='space-y-1.5'>
      <Label>
        {label}
      </Label>

      <Label variant="description" className='relative pr-6'>
        {labelDesc}
        <CopyValue value={copyFormattedData} className='absolute bottom-0 right-1' />
      </Label>

      <div className={cn('overflow-auto border border-input px-3 py-2 rounded-md text-sm h-48', responseClassName)}>
        {
          isProcessing ? (
            <Loading variant='centered' />
          ) : (
            <>
              {[BLOCK_DATA_TYPE.QUESTIONS, BLOCK_DATA_TYPE.DATA_POINTS].includes(type) ? (
                <ul className='list-disc'>
                  {displayData?.map(item => (
                    <li key={item.id} className='ml-3 font-normal'>
                      <span className='font-extralight'>
                        {item?.text}
                      </span>
                      {type !== BLOCK_DATA_TYPE.QUESTIONS && ': '}
                      <span className={classNames('font-semibold', { 'block': type === BLOCK_DATA_TYPE.QUESTIONS })}>
                        {item?.value || "-"}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <MarkdownRenderer
                  markdown={displayData?.[0]?.value}
                />
              )}
            </>
          )
        }
      </div>
    </div >
  )
}

