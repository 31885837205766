import { http } from "services/http";

import { IClientManagementApi } from "./types";
import {
  ICreateClientPayload,
  ICreateDataRecordPayload,
  IDeleteClientPayload,
  IDeleteDataRecordPayload,
  IGetAiDataSearchAnswerPayload,
  IGetAiDataSearchExtraInfoPayload,
  IGetClientPayload,
  ILinkGoogleDriveFolderPayload,
  ISyncPeopleforcePayload,
  ISyncRedtailPayload,
  ISyncSalesforcePayload,
} from "store/clientManagement/types";
import { API_ROUTES } from "services/api/constants";

export const clientManagement: IClientManagementApi = {
  getClientsList: () =>
    http
      .get(API_ROUTES.clientManagement.getClientsList)
      .then((response) => response.data),
  createClient: (payload: ICreateClientPayload) =>
    http
      .post(API_ROUTES.clientManagement.createClient, payload)
      .then((response) => response.data),
  getClient: (payload: IGetClientPayload) =>
    http
      .post(API_ROUTES.clientManagement.getClient(payload.id))
      .then((response) => response.data),
  deleteClient: (payload: IDeleteClientPayload) =>
    http
      .delete(API_ROUTES.clientManagement.deleteClient(payload.id))
      .then((response) => response.data),
  createDataRecord: (payload: ICreateDataRecordPayload) =>
    http
      .post(
        API_ROUTES.clientManagement.createDataRecord(payload.client_id),
        payload
      )
      .then((response) => response.data),
  linkGoogleDriveFolder: (payload: ILinkGoogleDriveFolderPayload) =>
    http
      .post(
        API_ROUTES.clientManagement.linkGoogleDriveFolder(payload.client_id),
        payload
      )
      .then((response) => response.data),
  getDataRecordsList: (clientId: string) =>
    http
      .get(API_ROUTES.clientManagement.getDataRecordsList(clientId))
      .then((response) => response.data),
  deleteDataRecord: (payload: IDeleteDataRecordPayload) =>
    http
      .delete(API_ROUTES.clientManagement.deleteDataRecord(payload))
      .then((response) => response.data),
  getAiDataSearchAnswer: (payload: IGetAiDataSearchAnswerPayload) =>
    http
      .post(
        API_ROUTES.clientManagement.getAiDataSearchAnswer(payload.clientId),
        payload
      )
      .then((response) => response.data),
  getAiDataSearchWhoIsClient: (payload: IGetAiDataSearchExtraInfoPayload) =>
    http
      .get(
        API_ROUTES.clientManagement.getAiDataSearchWhoIsClient(payload.clientId)
      )
      .then((response) => response.data),
  getAiDataSearchDoAgenda: (payload: IGetAiDataSearchExtraInfoPayload) =>
    http
      .get(
        API_ROUTES.clientManagement.getAiDataSearchDoAgenda(payload.clientId)
      )
      .then((response) => response.data),
  syncSalesforce: (payload: ISyncSalesforcePayload) =>
    http
      .post(
        API_ROUTES.clientManagement.syncSalesforce(payload.client_id),
        payload
      )
      .then((response) => response.data),
  importRedtail: (payload: void) =>
    http
      .get(API_ROUTES.clientManagement.importRedtail)
      .then((response) => response.data),
  syncRedtail: (payload: ISyncRedtailPayload) =>
    http
      .post(API_ROUTES.clientManagement.syncRedtail(payload.client_id), payload)
      .then((response) => response.data),
  importPeopleforce: (payload: void) =>
    http
      .get(API_ROUTES.clientManagement.importPeopleforce)
      .then((response) => response.data),
  syncPeopleforce: (payload: ISyncPeopleforcePayload) =>
    http
      .post(API_ROUTES.clientManagement.syncPeopleforce(payload.client_id), payload)
      .then((response) => response.data),
};
