import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector, useDevFlag, useMixpanel, useTranslation } from 'hooks';
import {
  ClientDataRecordType, IClientData, IClientDataRecord, IImportPeopleforceResponse, deleteClient, getClientsList, getDataRecordsList, importPeopleforce, selectActiveClient, selectClientDataRecordsList,
  selectClientsList, selectIsDeleteClientProcessing, selectIsGetClientsListProcessing, selectIsGetDataRecordsListProcessing, selectIsImportingFromToPeopleforce, setActiveClient
} from 'store/clientManagement';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { BriefcaseBusinessIcon, CirclePlus, LinkIcon, MessagesSquareIcon, NotepadTextIcon, RefreshCcwIcon, Trash2Icon } from 'lucide-react';
import { toast } from 'react-toastify';
import { cn } from 'lib/utils';
import { Card, CardHeader } from 'components/ui/card';
import { CardTitle } from 'components/ui/card';
import { AiConversationType, AskAIConversation } from 'components/common/app/_general/AskAIConversation';
import { AddClientModal } from 'components/common/app/_general/AddClientModal';
import { ClientDataRecordModal, RESOURCE_MODAL_MODE } from 'components/common/app/clients/ClientDataRecordModal';
import { Loading } from 'components/layout-atoms/Loading';
import { ConfirmActionDialog } from 'components/common/_atoms/ConfirmActionDialog';
import { Button } from 'components/ui/button';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { Fieldset } from 'components/ui/fieldset';
import { InfoCard } from 'components/layout-atoms/InfoCard';
import { ItemSelectedIcon } from 'components/common/_atoms/ItemSelectedIcon';
import { MIXPANEL } from 'const';
import { SearchInput } from 'components/common/_atoms/SearchInput';
import { IImportRedtailResponse, importRedtail, selectIsImportingFromToRedtail } from 'store/clientManagement';
import { selectProfileHasConnectedPeopleforce, selectProfileHasConnectedRedtail, selectProfileIndustryType } from 'store/profile';
import { Separator } from 'components/ui/separator';
import { ConnectRedtail } from 'components/common/app/settings/connections/ConnectRedtail';
import { UploadAudioDataRecordModal } from 'components/common/app/clients/UploadAudioDataRecordModal';
import { getTemplates } from 'store/templates-management';
import { OrgIndustryTypeEnum } from 'store/organizations';

interface IOpenResourceModal {
  mode: RESOURCE_MODAL_MODE;
  type: ClientDataRecordType;
  data?: IClientDataRecord
}

export const ClientsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();
  const { isDevEnv } = useDevFlag()

  const [isAddClientFormVisible, setIsAddClientFormVisible] = useState(false)
  const [isDeleteClientDialogVisible, setIsDeleteClientDialogVisible] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)
  const [modalType, setModalType] = useState(ClientDataRecordType.TEXT);
  const [modalMode, setModalMode] = useState(RESOURCE_MODAL_MODE.ADD)
  const [modalData, setModalData] = useState<IClientDataRecord>()

  const [displayList, setDisplayList] = useState<IClientData[]>([])
  const [getClientsCallInit, setGetClientsCallInit] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const clientsList = useSelector(selectClientsList);
  const isGetClientsListProcessing = useSelector(selectIsGetClientsListProcessing);
  const activeClient = useSelector(selectActiveClient);
  const isDeleteClientProcessing = useSelector(selectIsDeleteClientProcessing);

  const clientDataRecordsList = useSelector(selectClientDataRecordsList);
  const isGetDataRecordsListProcessing = useSelector(selectIsGetDataRecordsListProcessing);

  const isImportingFromToRedtail = useSelector(selectIsImportingFromToRedtail)
  const hasConnectedRedtail = useAppSelector(selectProfileHasConnectedRedtail);
  const hasConnectedPeopleforce = useAppSelector(selectProfileHasConnectedPeopleforce);
  const isImportingFromToPeopleforce = useAppSelector(selectIsImportingFromToPeopleforce);

  const profileIndustryType = useAppSelector(selectProfileIndustryType);

  useEffect(() => {
    dispatch(getClientsList())
    dispatch(getTemplates());

  }, [dispatch])

  useEffect(() => {
    if (!activeClient) return;

    dispatch(getDataRecordsList(activeClient.id))
    setGetClientsCallInit(true);
  }, [activeClient, dispatch])

  useEffect(() => {
    if (getClientsCallInit && clientsList && clientsList?.length === 0 && !isGetClientsListProcessing) {
      setIsAddClientFormVisible(true);
    }
  }, [clientsList, isGetClientsListProcessing, getClientsCallInit])

  useEffect(() => {
    if (!searchValue) {
      setDisplayList(clientsList)
    } else {
      const lowerCaseValue = searchValue?.toLowerCase();

      setDisplayList(clientsList.filter(item => {
        const fullName = `${item?.firstName} ${item?.lastName}`.toLowerCase();
        return fullName?.includes(lowerCaseValue)
      }))
    }
  }, [clientsList, searchValue]);

  const handleDeleteClient = async () => {
    if (activeClient && activeClient.id) {
      const response = await dispatch(deleteClient({ id: activeClient.id }));

      // Call with success
      if (!response.hasOwnProperty('error')) {
        setIsDeleteClientDialogVisible(false);
        toast(t('page.ClientsPage.deleteClientAction.deleteSuccess'), { type: 'success' })
      }
    }
  }

  const openResourceModal = ({ mode, type, data }: IOpenResourceModal) => {
    setIsModalVisible(true);
    setModalMode(mode);
    setModalType(type);
    data && setModalData(data);
  }

  const getFormattedDate = (dateString: string) => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' as const };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
  }

  const handleImportFromRedtail = async () => {
    const response = await dispatch(importRedtail())
    if (!response?.hasOwnProperty('error')) {
      const payload = response.payload as IImportRedtailResponse;

      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.IMPORTED_REDTAIL });
      toast(payload?.message, { type: 'success' })
      dispatch(getClientsList())
    }
  }

  const handleImportFromPeopleforce = async () => {
    const response = await dispatch(importPeopleforce())
    if (!response?.hasOwnProperty('error')) {
      const payload = response.payload as IImportPeopleforceResponse;

      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.IMPORTED_PEOPLEFORCE });
      toast(payload?.message, { type: 'success' })
      dispatch(getClientsList())
    }
  }

  return (
    <GeneralLayout isOneScreenOnly={true}>
      <div className="flex flex-1 overflow-auto gap-6">
        <Fieldset
          className='basis-1/4'
          legend={t('page.ClientsPage.clients.header')}
          fieldsetActions={[
            {
              onClick: () => {
                trackEvent({ action: MIXPANEL.ACTION.CLIENTS.ADD_CLIENT })
                setIsAddClientFormVisible(true)
              },
              tooltip: t('page.ClientsPage.clients.tooltip'),
              content: <CirclePlus className='size-4' />
            }
          ]}
        >
          {/* {isDevEnv && (  */}
          <>
            {profileIndustryType === OrgIndustryTypeEnum.RIA && hasConnectedRedtail && (
              <Button
                onClick={handleImportFromRedtail}
                loading={isImportingFromToRedtail}
              >
                {t('page.ClientsPage.clients.importFromCrm')}
              </Button>
            )}

            {profileIndustryType === OrgIndustryTypeEnum.Recruiting && hasConnectedPeopleforce && (
              <Button
                onClick={handleImportFromPeopleforce}
                loading={isImportingFromToPeopleforce}
              >
                {t('page.ClientsPage.clients.importFromCrm')}
              </Button>
            )}

            {!hasConnectedRedtail && profileIndustryType === OrgIndustryTypeEnum.RIA && (
              <ConnectRedtail />
            )}

            <Separator className='my-2 bg-primary' />
          </>
          {/* )} */}

          <SearchInput
            onClearSearch={() => setSearchValue('')}
            onChange={(value) => setSearchValue(value)}
            containerClassName="p-0"
          />
          <div className="grow overflow-auto">
            <div className='flex flex-col gap-3'>
              {
                !isGetClientsListProcessing ?
                  displayList?.map(client => (
                    <Card
                      key={client.id}
                      variant="item"
                      className={cn('cursor-pointer', { 'border-primary': client?.id === activeClient?.id })}
                      onClick={() => dispatch(setActiveClient(client))}
                    >
                      <CardHeader className="p-3">
                        <CardTitle className="text-sm">{client?.firstName} {client?.lastName}</CardTitle>
                      </CardHeader>
                      {client?.id === activeClient?.id && (
                        <ItemSelectedIcon />
                      )}
                    </Card>
                  ))
                  : (
                    <Loading variant="item" />
                  )
              }
              {displayList?.length === 0 && (
                <InfoCard
                  variant="item"
                  title=''
                  description={t('page.ClientsPage.search.noFilterData') as string}
                />
              )}
            </div>
          </div>
        </Fieldset>

        <Fieldset legend={t('page.ClientsPage.search.header')} className='basis-2/4'>
          {isGetDataRecordsListProcessing ? (
            <Loading className='flex justify-center' />
          ) : (
            <Fragment>
              {(!clientsList || clientsList?.length === 0) && (
                <InfoCard
                  variant="item"
                  title={t('page.ClientsPage.search.noClients')}
                  description={t('page.ClientsPage.search.noClientsDesc') as string}
                />
              )}

              {clientsList?.length > 0 && !activeClient && (
                <InfoCard
                  variant="item"
                  title={t('page.ClientsPage.search.noActiveClient')}
                  description={t('page.ClientsPage.search.noActiveClientDesc') as string}
                />
              )}

              {activeClient && !clientDataRecordsList?.length && (
                <InfoCard
                  variant="item"
                  title={t('page.ClientsPage.search.noData')}
                  description={t('page.ClientsPage.search.noDataDesc') as string}
                />
              )}

              {activeClient && clientDataRecordsList?.length > 0 && (
                <AskAIConversation
                  botId=''
                  clientId={activeClient?.id}
                  type={AiConversationType.CLIENT_DATA}
                  placeholder={t('page.ClientsPage.search.placeholder') as string}
                  hasExtraActions={true}
                />
              )}
            </Fragment>
          )}
        </Fieldset>

        <div className='flex flex-1 overflow-auto gap-6 flex-col basis-1/4'>
          {
            activeClient && (
              <>
                <Fieldset
                  className='grow-0 overflow-visible'
                  legend={<span>{activeClient?.firstName}&nbsp;{activeClient?.lastName}</span>}
                  fieldsetActions={[
                    {
                      onClick: () => {
                        setIsDeleteClientDialogVisible(true)
                        trackEvent({ action: MIXPANEL.ACTION.CLIENTS.DELETE_CLIENT })
                      },
                      tooltip: t('page.ClientsPage.deleteClientAction.tooltip'),
                      content: <Trash2Icon className='size-4' />
                    }
                  ]}
                >
                  <div className='flex flex-col gap-3'>
                    <Button variant="outline" onClick={() => {
                      trackEvent({ action: MIXPANEL.ACTION.CLIENTS.ADD_CLIENT_DATA })
                      openResourceModal({
                        mode: RESOURCE_MODAL_MODE.ADD,
                        type: ClientDataRecordType.TEXT
                      })
                    }}>
                      {t('page.ClientsPage.shareData.btn.addClientData')}
                    </Button>

                    <Button variant="outline" onClick={() => {
                      trackEvent({ action: MIXPANEL.ACTION.CLIENTS.CONNECT_TO_DRIVE })
                      openResourceModal({
                        mode: RESOURCE_MODAL_MODE.ADD,
                        type: ClientDataRecordType.G_DRIVE
                      })
                    }}
                    >
                      {t('page.ClientsPage.shareData.btn.connect')}
                    </Button>

                    <Button variant="outline" className='w-full'
                      onClick={() => {
                        setIsUploadModalVisible(true);
                      }}>
                      {t('page.ClientsPage.shareData.btn.upload')}
                    </Button>
                  </div>
                </Fieldset>

                <Fieldset
                  legend={t('page.ClientsPage.dataRecords.header')}
                  fieldsetActions={[
                    {
                      onClick: () => {
                        dispatch(getDataRecordsList(activeClient.id))
                      },
                      tooltip: t('page.ClientsPage.dataRecords.tooltip'),
                      content: !isGetDataRecordsListProcessing ? <RefreshCcwIcon className='size-4' /> : <Loading />
                    }
                  ]}
                >
                  {!isGetDataRecordsListProcessing ? (
                    clientDataRecordsList?.length ?
                      clientDataRecordsList?.map(dataRecord => (
                        <Card
                          key={dataRecord.id}
                          variant="item"
                          className="cursor-pointer"
                          onClick={() => {
                            if ([ClientDataRecordType.G_DRIVE, ClientDataRecordType.TEXT].includes(dataRecord.type)) {
                              trackEvent({ action: MIXPANEL.ACTION.CLIENTS.VIEW_DATA_RECORD })
                              openResourceModal({
                                mode: RESOURCE_MODAL_MODE.VIEW,
                                type: dataRecord.type,
                                data: dataRecord
                              })
                            }
                            if ([ClientDataRecordType.CLIENT_MEETING, ClientDataRecordType.IN_PERSON_MEETING].includes(dataRecord.type)) {
                              router.navigate(ROUTES.APP.CLIENTS.MEETING.replace(':id', dataRecord.recallBotId))
                            }
                          }}
                        >
                          <CardHeader className="p-3">
                            <CardTitle className="text-sm flex flex-row items-center gap-3">
                              {dataRecord.type === ClientDataRecordType.G_DRIVE && <LinkIcon className="size-4" />}
                              {dataRecord.type === ClientDataRecordType.TEXT && <NotepadTextIcon className="size-4" />}
                              {dataRecord.type === ClientDataRecordType.CLIENT_MEETING && <BriefcaseBusinessIcon className="size-4" />}
                              {dataRecord.type === ClientDataRecordType.IN_PERSON_MEETING && <MessagesSquareIcon className="size-4" />}
                              {dataRecord.title}
                              {[ClientDataRecordType.CLIENT_MEETING].includes(dataRecord.type) && ` - ${getFormattedDate(dataRecord.createdAt || '')}`}
                              {[ClientDataRecordType.IN_PERSON_MEETING].includes(dataRecord.type) && (dataRecord?.title === 'In-person meeting' || dataRecord?.title === 'Client meeting') && ` - ${getFormattedDate(dataRecord.createdAt || '')}`}
                            </CardTitle>
                          </CardHeader>
                        </Card>
                      )) : (
                        <InfoCard
                          variant="item"
                          title={t('page.ClientsPage.dataRecords.noRecords')}
                          description={t('page.ClientsPage.dataRecords.noRecordsDesc') as string}
                        />
                      )
                  ) : (
                    <Loading variant="item" />
                  )}
                </Fieldset>
              </>
            )
          }
        </div>
      </div >

      <AddClientModal isVisible={isAddClientFormVisible} setIsVisible={setIsAddClientFormVisible} />

      <ClientDataRecordModal
        type={modalType}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        client={activeClient}
        mode={modalMode}
        data={modalData}
      />

      <UploadAudioDataRecordModal
        isVisible={isUploadModalVisible}
        setIsVisible={setIsUploadModalVisible}
        client={activeClient}
      />

      <ConfirmActionDialog
        onOk={handleDeleteClient}
        isVisible={isDeleteClientDialogVisible}
        setIsVisible={setIsDeleteClientDialogVisible}
        isProcessing={isDeleteClientProcessing}
        title={t('page.ClientsPage.deleteClientAction.title')?.replace('{{clientName}}', `${activeClient?.firstName} ${activeClient?.lastName}`)}
        description={t('page.ClientsPage.deleteClientAction.description')}
      />
    </GeneralLayout >
  );
};