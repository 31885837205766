import { Fragment } from 'react';
import { useAppDispatch, useAppSelector, useTranslation } from 'hooks';
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { IConnectPeopleforcePayload, connectPeopleforce, selectIsConnectingToPeopleforce } from 'store/peopleforce';

interface IProps {
  isVisible: boolean;
  setIsVisible: (visibility: boolean) => void;
  onCreateCompleted?: (payload: void) => void;
}

export const ConnectPeopleforceModal = ({ isVisible, setIsVisible, onCreateCompleted }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isProcessing = useAppSelector(selectIsConnectingToPeopleforce);

  const formSchema = z.object({
    apiKey: z.string().min(1, t('errors.required') as string),
  });

  const form = useForm<Required<IConnectPeopleforcePayload>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      apiKey: '',
    }
  });

  const handleConnectToPeopleforce = async (values: IConnectPeopleforcePayload) => {
    const response = await dispatch(connectPeopleforce(values));

    if (!response?.hasOwnProperty('error')) {
      form.reset();
      setIsVisible(false);
      onCreateCompleted && onCreateCompleted();
    }
  };

  return (
    <Fragment>
      <Dialog open={isVisible} onOpenChange={open => setIsVisible(open)}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{t('component.ConnectPeopleforceModal.title')}</DialogTitle>
            <DialogDescription>
              {t('component.ConnectPeopleforceModal.description')}
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleConnectToPeopleforce)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="apiKey"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.ConnectPeopleforceModal.label.apiKey')} *
                    </FormLabel>
                    <FormControl>
                      <Input {...field} autoComplete="off" data-1p-ignore disabled={isProcessing} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button type="submit" loading={isProcessing}>
                  {t('component.ConnectPeopleforceModal.btn')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}; 