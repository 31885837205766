import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "store";
import { UserRoleInOrgEnum } from "store/users";

const getMyProfileState = (state: RootState) => state.myProfile;

export const selectProfileDetailsIsLoading = createSelector(
  [getMyProfileState],
  ({ loading }) => loading
);

export const selectProfileDetails = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => profileDetails
);

export const selectProfileDetailsFullName = createSelector(
  [getMyProfileState],
  ({ profileDetails }) =>
    `${profileDetails?.firstName} ${profileDetails?.lastName}`
);

export const selectProfileRole = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => profileDetails.role
);

export const selectProfileIndustryType = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => profileDetails.industryType
);

export const selectProfilePreferences = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => profileDetails.preferences
);

export const selectProfileHasConnectedRedtail = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => !!profileDetails?.redtailCRM
);

export const selectProfileHasConnectedPeopleforce = createSelector(
  [getMyProfileState],
  ({ profileDetails }) => !!profileDetails?.peopleforceCRM
);

export const selectProfileIsOrgAdmin = createSelector(
  [getMyProfileState],
  ({ profileDetails }) =>
    profileDetails?.organisations?.[0]?.role === UserRoleInOrgEnum.Admin
);
