export const MIXPANEL = {
  TYPE: {
    VISITED: "VISITED",
    CLICKED: "CLICKED",
    ASK_AI: "ASK_AI",
  },
  ACTION: {
    CONSENT: {
      YES: "consent-yes",
      NO: "consent-no",
    },
    SIGN_UP:{
      CREATE_ACCOUNT: 'create-account'
    },
    CLIENTS: {
      ADD_CLIENT: "add-client",
      DELETE_CLIENT: "delete-client",
      ADD_CLIENT_DATA: "add-client-data",
      CONNECT_TO_DRIVE: "connect-to-drive",
      VIEW_DATA_RECORD: "view-data-record",
      UPLOAD_DATA_RECORD: "upload-data-record"
    },
    COPILOT: {
      LAUNCH_COPILOT_ONLINE: "launch-copilot-online",
      LAUNCH_COPILOT_IN_PERSON: "launch-copilot-in-person",
      UPLOAD_IN_PERSON_RECORDING: "upload-in-person-recording",
      GO_TO_SUMMARY: "go-to-summary-page",
      IN_PERSON:{
        UPLOAD: 'in-person_upload-recording',
        COMPLETED_LEAVE_SCREEN: "in-person_completed_leave-screen",
        START_RECORDING: "in-person_start-recording",
        STOP_RECORDING: "in-person_stop-recording",
        PAUSE_RECORDING: "in-person_pause-recording",
        PLAY_RECORDING: "in-person_play-recording",
        DELETE_RECORDING: "in-person_delete-recording"
      },
      SUMMARY: {
        RETURN_TO_MEETING: "return-to-meeting",
        NEW_CALL: "join-new-call",
        DOWNLOAD_VIDEO: "download-video",
        DOWNLOAD_AUDIO: "download-audio",
        DOWNLOAD_TRANSCRIPT: "download-transcript",
      },
    },
    PARAPLANNER_AI: {
      UPLOAD_FILE: "upload-files",
      SHARE_LINK: "share-secure-link",
      STATEMENT_EXTRACTION_REQUEST: "statement-extraction-request",
      STATEMENT_EXTRACTION_DOWNLOAD: "statement-extraction-download",
    },
    SETTINGS: {
      CREDENTIALS: { SHARE: "share-credentials" },
      CONNECTIONS: {
        CONNECT_PEOPLEFORCE: "connect-peopleforce",
        DISCONNECT_PEOPLEFORCE: "disconnect-peopleforce",
        CONNECT_CALENDAR: "connect-calendar",
        DISCONNECT_CALENDAR: "disconnect-calendar",
        CONNECT_REDTAIL: "connect-redtail",
        DISCONNECT_REDTAIL: "disconnect-redtail",
        IMPORTED_REDTAIL: "imported-redtail",
        IMPORTED_PEOPLEFORCE: "imported-peopleforce"
      },
      TEMPLATES: {
        CREATE_TEMPLATE: "create-template",
        UPDATE_TEMPLATE: "update-template",
        DELETE_TEMPLATE: "delete-template",
      },
      CARDS: {
        CREATE_CARD: "create-card",
        UPDATE_CARD: "update-card",
        DELETE_CARD: "delete-card",
      },
      COMPLIANCE: { SAVE: "save-compliance" },
    },
  },
};
