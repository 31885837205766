import { http } from "services/http";
import { API_ROUTES } from "../constants";
import { IPeopleforceApi } from "./types";
import { IConnectPeopleforcePayload } from "store/peopleforce";

export const peopleforce: IPeopleforceApi = {
  connectPeopleforce: (payload: IConnectPeopleforcePayload) =>
    http
      .post(API_ROUTES.peopleforce.connectPeopleforce, payload)
      .then((response) => response.data),
  disconnectPeopleforce: (payload: void) =>
    http
      .delete(API_ROUTES.peopleforce.disconnectPeopleforce)
      .then((response) => response.data),
}; 