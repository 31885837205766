import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useMixpanel, useTranslation } from 'hooks';
import { Button } from 'components/ui/button';
import { MIXPANEL } from 'const';
import { Label } from 'components/ui/label';
import { getProfileDetails, selectProfileHasConnectedPeopleforce } from 'store/profile';
import { toast } from 'react-toastify';
import { ConnectPeopleforceModal } from '../../_general/ConnectPeopleforceModal';
import { disconnectPeopleforce, selectIsConnectingToPeopleforce } from 'store/peopleforce';

interface IProps {
  title?: string;
  descriptionConnect?: string;
  descriptionDisconnect?: string;
}

export const ConnectPeopleforce = ({ title, descriptionConnect, descriptionDisconnect }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel({ trackPageVisit: false });

  const isProcessing = useAppSelector(selectIsConnectingToPeopleforce);
  const hasConnectedPeopleforce = useAppSelector(selectProfileHasConnectedPeopleforce);

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    return () => {
      setIsModalVisible(false);
    };
  }, []);

  const handleConnectPeopleforce = () => {
    setIsModalVisible(true);
  };

  const onConnectCompleted = () => {
    trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.CONNECT_PEOPLEFORCE });
    toast(t('component.ConnectPeopleforceModal.connectSuccess'), { type: 'success' });
    dispatch(getProfileDetails());
  };

  const handleDisconnectPeopleforce = async () => {
    const response = await dispatch(disconnectPeopleforce());

    if (!response?.hasOwnProperty('error')) {
      trackEvent({ action: MIXPANEL.ACTION.SETTINGS.CONNECTIONS.DISCONNECT_PEOPLEFORCE });
      toast(t('component.ConnectPeopleforceModal.disconnectSuccess'), { type: 'success' });
      dispatch(getProfileDetails());
    }
  };

  return (
    <div className="flex flex-row items-center justify-between gap-6">
      <div className="space-y-1.5">
        <Label>{title || t('component.ConnectPeopleforce.title')}</Label>
        <Label variant="description">
          {!hasConnectedPeopleforce
            ? descriptionConnect || t('component.ConnectPeopleforce.descriptionConnect')
            : descriptionDisconnect || t('component.ConnectPeopleforce.descriptionDisconnect')}
        </Label>
      </div>

      {!hasConnectedPeopleforce ? (
        <Button type="button" onClick={handleConnectPeopleforce} loading={isProcessing}>
          {t('general.connect')}
        </Button>
      ) : (
        <Button type="button" variant="destructive" onClick={handleDisconnectPeopleforce} loading={isProcessing}>
          {t('general.disconnect')}
        </Button>
      )}

      <ConnectPeopleforceModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        onCreateCompleted={onConnectCompleted}
      />
    </div>
  );
}; 